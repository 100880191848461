const MANAGER_FIELDS_FRAGMENT = `
fragment managerFields on ManagerUser {
  id
  name
  email
  status
  resetPasswordSentAt
  isRemovable
  isMovable
  lastSignInAt
  generalManager
  checkoutManager
  updatedAt
  role
  teams {
    id
    name
  }
}
`

const TECHNICIAN_FIELDS_FRAGMENT = `
fragment technicianFields on TechnicianUser {
  id
  name
  email
  status
  isRemovable
  isMovable
  lastSignInAt
  updatedAt
  checkoutManager
  role
  divisions {
    id
    name
  }
  teams {
    id
    name
  }
}
`

const PLANNER_FIELDS_FRAGMENT = `
fragment plannerFields on PlannerUser {
  id
  name
  email
  status
  resetPasswordSentAt
  isRemovable
  isMovable
  lastSignInAt
  updatedAt
  role
  teams {
    id
    name
  }
}
`

export const DIVISIONS_QUERY = `
query {
  manager {
    account {
      divisions {
        id
        name
      }
    }
  }
}
`

export const USERS_QUERY = `
${MANAGER_FIELDS_FRAGMENT}
${TECHNICIAN_FIELDS_FRAGMENT}
${PLANNER_FIELDS_FRAGMENT}

query FindUsers($branchId: Int!) {
  manager {
    account {
      id
    }

    currentBranch: branches(id: $branchId) {
      divisions {
        id
        name
      }
      teams {
        id
        name
      }
      technicians: technicians(status: "active") {
        ...technicianFields
      }
      inactive: technicians(status: "inactive") {
        ...technicianFields
      }
      generalManagers: managers(generalManager: true) {
        ...managerFields
      }
      checkoutManagers: managers(checkoutManager: true) {
        ...managerFields
      }
      managers: managers(generalManager: false, checkoutManager: false) {
        ...managerFields
      }
      planners: planners {
        ...plannerFields
      }
    }
  }
}
`

export const INTERNAL_USERS_QUERY = `
${MANAGER_FIELDS_FRAGMENT}
${TECHNICIAN_FIELDS_FRAGMENT}
${PLANNER_FIELDS_FRAGMENT}

query FindUsers($branchId: Int!, $accountId: Int!) {
  internal {
    accounts(id: $accountId) {
      nodes {
        id

        currentBranch: branches(id: $branchId) {
          divisions {
            id
            name
          }
          teams {
            id
            name
          }
          technicians: technicians(status: "active") {
            ...technicianFields
          }
          inactive: technicians(status: "inactive") {
            ...technicianFields
          }
          generalManagers: managers(generalManager: true) {
            ...managerFields
          }
          checkoutManagers: managers(checkoutManager: true) {
            ...managerFields
          }
          managers: managers(generalManager: false, checkoutManager: false) {
            ...managerFields
          }
          planners: planners {
            ...plannerFields
          }
        }
      }
    }
  }
}
`

export const MOVE_TECHNICIAN_QUERY = `
mutation TechnicianMove($id: Int!, $branchIds: [Int!]!) {
  technicianMove(id: $id, branchIds: $branchIds) {
    errors
  }
}
`

export const MOVE_USER_QUERY = `
mutation UserMove($id: Int!, $branchIds: [Int!]!) {
  userMove(id: $id, branchIds: $branchIds) {
    errors
  }
}
`

export const UPDATE_MANAGER_QUERY = `
${MANAGER_FIELDS_FRAGMENT}

mutation ManagerUpdate($id: Int!, $attributes: ManagerAttributes!, $branchId: Int) {
  managerUpdate(id: $id, attributes: $attributes, branchId: $branchId) {
    errors
    result {
      ...managerFields
      teams {
        id
        name
      }
    }
  }
}
`

export const USER_CHECKOUTS_QUERY = `
query FindCheckouts($branchId: Int!, $userId: Int!, $status: CheckoutStatusesEnum!) {
  manager {
    currentBranch: branches(id: $branchId) {
      technicians: technicians(id: $userId) {
        checkouts(status: $status, branchId: $branchId) {
          status
          name
          asap
          cost
          createdAt
          updatedAt
          id
          totalItems
          totalUnits
        }
      }
    }
  }
}
`

export const INTERNAL_USER_CHECKOUTS_QUERY = `
query FindCheckouts($branchId: Int!, $accountId: Int!, $userId: Int!, $status: CheckoutStatusesEnum!) {
  internal {
    accounts(id: $accountId) {
      nodes {
        id

        currentBranch: branches(id: $branchId) {
          technicians: technicians(id: $userId) {
            checkouts(status: $status, branchId: $branchId) {
              status
              name
              asap
              cost
              createdAt
              updatedAt
              id
              totalItems
              totalUnits
            }
          }
        }
      }
    }
  }
}
`
